.Wrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    height: 110vh;
    align-items: center;
}
.Wrapper h2 {
    font-size: 110px;
    margin: 0;
    color: #0096C7;
    font-family: "Inter";
}
.Wrapper a {
    border: 1.8px solid #0096C7;
    color: #0096C7;
    background-color: transparent;
    padding: 10px 20px;
    border-radius: 40px;
    font-family: "Inter";
    font-size: 20px;
    font-weight: 600;
    margin-top: 20px;
    text-decoration: none;
    cursor: pointer;
}
.Wrapper img {
    width: 40%;
    margin: 0 auto;    
}
@media screen and (max-width: 500px) {
    .Wrapper h2 {
font-size: 50px;

    }
}
.WrapperBody {
    background-color: #0061E4;
    width: 100%;
    height: auto;
    background-size: cover;
    background-repeat: no-repeat;
    padding-top: 52px;
}

.Container {
    max-width: 1250px;
    width: 100%;
    margin: 0 auto;
    padding: 0 10px;
}

.Network {
    width: 60%;
    display: flex;
    justify-content: space-between;
}

.Network i {
    color: white;
    font-size: 30px;
}

.Container h2 {
    font-size: 40px;
    font-family: 'Inter';
    color: white;
}

.NetworkMedia {
    display: none;
}

@media only screen and (max-width: 882px) {
    .Container {
        width: 90%;

    }

    .WrapperBody {
        height: 100%;
    }

    .Network {
        display: none;
    }

    .NetworkMedia i {
        color: white;
        font-size: 30px;
    }

    .NetworkMedia {
        display: flex;
        justify-content: space-between;
        width: 50%;
        margin: 0 auto;
        margin-top: 20px;
        padding-bottom: 20px;
    }
}

.Wrapper ul {
    margin: 0;
    padding: 0;
}

.Wrapper ul li {
    text-decoration: none;
    list-style-type: none;

    margin-bottom: 15px;
}

.Wrapper ul li a i {
    color: white;
    font-size: 24px;
    margin-right: 5px;
}

.Wrapper ul li a {
    text-decoration: none;
}

.FooterLink {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    text-transform: uppercase;
    color: #FFFFFF;

    opacity: 0.5;
}

.Paragraph {
    font-family: 'Actor';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #FFFFFF;
    opacity: 0.5;
}

.FooterLinkHead {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #FFFFFF;
}

.link {
    text-decoration: none;
}

.Container button {
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #FFFFFF;
    border: 2px solid #C53720;
    background-color: transparent;
    padding: 10px 20px;
    cursor: pointer;
}

.linkCompany {
    text-align: center;
    width: 100%;
    color: gray;
    font-size: 14px;
    text-decoration: none;
    font-family: 'Roboto';
}

.company_footer {
    background-color: #000B3E;
    text-align: center;
    padding: 20px 0;
}


.Rounded {
    border-radius: 10px;
}
.request_form {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
}

.request_form > div {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
.request_form > div > i {
  font-size: 35px;
  margin-left: 10px;
  display: none;
}

.request_form > div > input {
  width: 100%;
  height: 100%;
  background: #ffffff;
  border-radius: 5px;
  outline: none;
  border: 2px solid transparent;
  padding: 15px 20px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 160%;
  color: #a0a0a0;
}

.request_form > div > input::placeholder {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 160%;
  color: #a0a0a0;
}

.request_form > button {
  background: #FDC506;
  border-radius: 5px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 160%;
  cursor: pointer;
  color: #ffffff;
  border: none;
  outline: none;
  padding: 15px 20px;
  width: 100%;
  text-align: center;
  transition: 0.3s;
}

.request_form > button:hover,
.request_form > button:focus {
  transform: scale(0.95);
  cursor: pointer;
}

.request_form > div > input:focus {
  border: 2px solid #2f66b8;
}

.request_form > div > input:valid {
  border: 2px solid green;
}

.bxs-check-circle {
  color: green;
}
.bxs-x-circle {
  color: red;
}

.request_form > div > input:valid ~ .bxs-check-circle {
  display: block;
}

.request_consultation_section {
  padding: 100px 0;
  background-image: url("../../assets/formBg.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
.Container{
  max-width: 1250px;
  width: 100%;
  margin: 0 auto;
  padding: 0 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-top: 50px;  
}
@media only screen and (max-width:882px) {
  .Container{
      width: 90%;
  }
}
.request_row {
  margin: 0 !important;
}

.request_col > h4 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 110%;
  color: #000000;
}

@media only screen and (max-width: 991px) {
  .request_col {
    margin-bottom: 30px;
  }
  .request_col > h4 {
    text-align: center;
  }
}

@media only screen and (max-width: 512px) {
  .request_col > h4 {
    text-align: center;
    font-size: 35px;
  }
}

@media only screen and (max-width: 425px) {
    .request_col > h4 {
      text-align: center;
      font-size: 30px;
    }
  }
